import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import GridlistingCard from '../GridlistingCard/index.js'

// display for individual grid tile
const DocumentTile = ({ document, width = "full" }) => {
    let display_pills = document.contentTypeFacet || document.membersOnly || document.journal_na_str
        || (document.category_na_str && document.category_na_str.length) || document.eventType || document.multimediaType_na_str;
    const convertToArray = (unknownVariable) => {
        
        if (!unknownVariable)
            return [];
        if (!Array.isArray(unknownVariable))
            return [unknownVariable];
        return unknownVariable;
            
    }
    return (
        <div className={clsx('col-12', width === 'half' && 'col-lg-6')}>
            <GridlistingCard
                classes="search-document"
                url={document.url}
                pills={[document.source, document.contentTypeFacet, document.journal_na_str, document.category_na_str, document.eventType, document.multimediaType_na_str]}
                membersOnly={document.membersOnly}
                title={document.title}
                summary={document.summary}
                date={document.date_na_dt}
                dateName={"Last Updated"}
                contentLength={document.contentLength}
                authors={(convertToArray(document.authorNames_na_str).concat(convertToArray(document.author)).join(', '))}
            />
        </div>
    );
};

DocumentTile.propTypes = {
    document: PropTypes.object
}

export default DocumentTile;
