import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

function ResultsMessage(props){
    const {total, searchTerm} = props
    const hasSearchTerm = searchTerm !== '';
    return (
        <h1 className="h3">{`${total} result${(total > 1) ? "s" : ""} `} {hasSearchTerm ? (<>for: <br /><i>{searchTerm}</i></>) : null}</h1>
    )
}

ResultsMessage.propTypes = {
    total: PropTypes.number.isRequired,
    searchTerm: PropTypes.string
}

export default ResultsMessage 