﻿import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import GridlistingCard from '../GridlistingCard/index.js';

// display for individual grid tile
const Multimedia = ({ document, width = "full" }) => {

    const absoluteImageSrc = document.images ? document.images[0] : "";
    return (
        <div className={clsx('col-12', width === 'half' && 'col-lg-6')}>
            <GridlistingCard
                classes="search-document"
                url={document.url}
                imageUrl={absoluteImageSrc}
                pills={[document.eventType, document.multimediaType_na_str]}
                membersOnly={document.membersOnly}
                title={document.title}
                summary={document.description}
                date={document.date_na_dt}
                dateName={"Published"}
                contentLength={document.contentLength}
            />
        </div>
    );
};

Multimedia.propTypes = {
    document: PropTypes.object
}

export default Multimedia;
