import {Utils, Telemetry} from '@bravo/react-search'

import IDSATemplate from './templates/idsaTemplate'
import IDSAPGTemplate from './templates/idsaPGTemplate'
import IDSAMediaTemplate from './templates/idsaMediaTemplate'
import IDSARTLNTemplateOld from './templates/idsaRTLNTemplateOld'
import IDSABlogListTemplate from './templates/idsaBlogListTemplate'
import IDSARecentBlogsTemplate from './templates/idsaRecentBlogsTemplate'
import IDSAAuthorTemplate from './templates/idsaAuthorTemplate'
import IDSABravoBlockTemplate from './templates/idsaBravoBlockTemplate'
import IDSARTLNCategoryTemplate from './templates/idsaRTLNCategoryTemplate'
import IDSARTLNTemplate from './templates/idsaRTLNTemplate'
import IDSARTLNRelatedTemplate from './templates/idsaRTLNRelated'
import IDSAGridListingTemplate from './templates/idsaGridListingTemplate'

const idsaTemplateId = 'bs-search-idsa'
const idsaPGTemplateId = 'bs-search-idsa-PracticeGuidelines'
const idsaBlogListTemplateId = 'bs-search-idsa-bloglist'
const idsaRecentBlogsTemplateId = 'bs-search-idsa-recent-blogs'
const idsaAuthorTemplateId = 'bs-search-idsa-author'
const idsaMediaTemplateId = 'bs-search-idsa-media'
const idsaRTLNTemplateIdOld = 'bs-search-idsa-CovidMicrosite'
const idsaRTLNCategoryTemplateId = 'bs-search-idsa-rtln-category'
const idsaRTLNTemplateId = 'bs-search-idsa-rtln-search'
const idsaRTLNResourcesTemplateId = 'bs-search-idsa-rtln-related'
const idsaGridListingTemplateId = 'bs-block-grid-listing'

const idsaBravoBlockID = 'bs-block-idsa'

const elementDatasetToBravoKeyMapping = {
    isMember: 'isMember',
    oupSsoPrefix: 'oupSsoPrefix',
    suppBaseUri: 'suppBaseUri',
    author: 'authorId',
    postId: 'postId',
    resourceId: 'resourceId',
    contentTypes: 'contentTypes',
    showFilters: 'showFilters',
    showLoadMore: 'showLoadMore',
    pageSize: 'pageSize',
    authors: 'authors',
    fullColumn: 'fullColumn'
}

const pgElementDatasetToBravoKeyMapping = {
    guidelinesBody: 'guidelinesSearchBody'
}

const dataFromBravoConfig = (bravoConfig, mapping, overrides = {}) => Object.keys(mapping).reduce((acc, key) => ({
    ...acc,
    [key]: overrides[key] || bravoConfig[mapping[key]]
}), {});

let _telemetry;

if(document.getElementById(idsaTemplateId)) {
    const ctx = initContext(idsaTemplateId)

    ctx.keys = {...ctx.keys,
        ...dataFromBravoConfig(ctx.element.dataset, elementDatasetToBravoKeyMapping)};

    IDSATemplate(ctx)  
} else if(document.getElementById(idsaPGTemplateId)) {
    const ctx = initContext(idsaPGTemplateId)

    ctx.keys = {...ctx.keys,
        ...dataFromBravoConfig(ctx.element.dataset, elementDatasetToBravoKeyMapping),
        ...dataFromBravoConfig(ctx.element.dataset, pgElementDatasetToBravoKeyMapping)};

    IDSAPGTemplate(ctx)
} else if(document.getElementById(idsaMediaTemplateId)) {
    const ctx = initContext(idsaMediaTemplateId)

    ctx.keys = {...ctx.keys,
        ...dataFromBravoConfig(ctx.element.dataset, elementDatasetToBravoKeyMapping)};

    IDSAMediaTemplate(ctx)  
} else if(document.getElementById(idsaRTLNTemplateIdOld)) {
    const ctx = initContext(idsaRTLNTemplateIdOld)

    ctx.keys = {...ctx.keys,
        ...dataFromBravoConfig(ctx.element.dataset, elementDatasetToBravoKeyMapping)};

    IDSARTLNTemplateOld(ctx)
} else if(document.getElementById(idsaBlogListTemplateId)) {
    const ctx = initContext(idsaBlogListTemplateId)

    ctx.keys = {...ctx.keys,
        ...dataFromBravoConfig(ctx.element.dataset, elementDatasetToBravoKeyMapping)};

    IDSABlogListTemplate(ctx)
} else if(document.getElementById(idsaAuthorTemplateId)) {
    const ctx = initContext(idsaAuthorTemplateId)

    ctx.keys = {...ctx.keys,
        ...dataFromBravoConfig(ctx.element.dataset, elementDatasetToBravoKeyMapping)};

    IDSAAuthorTemplate(ctx)
} else if(document.getElementById(idsaBravoBlockID)) {
    const ctx = initContext(idsaBravoBlockID)
    
    ctx.keys = {...ctx.keys,
        ...dataFromBravoConfig(ctx.element.dataset, elementDatasetToBravoKeyMapping)};     
    IDSABravoBlockTemplate(ctx)
} else if(document.getElementById(idsaRecentBlogsTemplateId)) {
    const ctx = initContext(idsaRecentBlogsTemplateId)
    
    ctx.keys = {...ctx.keys,
        ...dataFromBravoConfig(ctx.element.dataset, elementDatasetToBravoKeyMapping)};     
    IDSARecentBlogsTemplate(ctx)
} else if(document.getElementById(idsaRTLNCategoryTemplateId)) {
    const ctx = initContext(idsaRTLNCategoryTemplateId)

    ctx.keys = {...ctx.keys,
        ...dataFromBravoConfig(ctx.element.dataset, elementDatasetToBravoKeyMapping)};

    IDSARTLNCategoryTemplate(ctx)
} else if(document.getElementById(idsaRTLNTemplateId)) {
    const ctx = initContext(idsaRTLNTemplateId)

    ctx.keys = {...ctx.keys,
        ...dataFromBravoConfig(ctx.element.dataset, elementDatasetToBravoKeyMapping)};

    IDSARTLNTemplate(ctx)
} else if(document.getElementById(idsaRTLNResourcesTemplateId)) {
    const ctx = initContext(idsaRTLNResourcesTemplateId)

    ctx.keys = {...ctx.keys,
        ...dataFromBravoConfig(ctx.element.dataset, elementDatasetToBravoKeyMapping)};

    IDSARTLNRelatedTemplate(ctx)
} else if (document.getElementById(idsaGridListingTemplateId)) {
    const ctx = initContext(idsaGridListingTemplateId);

    ctx.keys = {
        ...ctx.keys,
        ...dataFromBravoConfig(ctx.element.dataset, elementDatasetToBravoKeyMapping)
    }

    IDSAGridListingTemplate(ctx);
}

function initContext(elementId){
    const element = document.getElementById(elementId)
    const bravo_keys = Utils.element.getKeysFromElement(element)
    const {isrtln} = element.dataset

    const keys = {
        ...bravo_keys,
        isrtln
    }
    if(!_telemetry && element.dataset.telemetryKey){
        _telemetry = Telemetry.init(keys, element.dataset.telemetryKey)
    }

    return {
        element,
        keys,
        telemetry: _telemetry
    }
}