'use strict';
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {Utils, SearchActions} from '@bravo/react-search'

import App from './app'
import reducer from '../reducers/default'

export default function IDSAGridListingTemplate({element, keys, telemetry}){
    Utils.store.createSearchStore(reducer, keys, { telemetry, hashLinking: false }).then(store => {
        const contentTypes = JSON.parse(keys['contentTypes'] ?? '[]');
        const healthTopics = JSON.parse(keys['healthTopics'] ?? '[]');
        const authors = (keys.authors && JSON.parse(keys.authors)) ?? [];

        const facets = {
            authorNames_na_str: authors,
            contentTypeFacet_na_str: contentTypes,
            categories_na_str: healthTopics
        }

        const settings = store.getState().search.settings;

        let newSettings = settings;

        if (keys.pageSize) {
            newSettings = {
                ...newSettings,
                query: {
                    ...newSettings.query,
                    pageSize: +keys.pageSize
                },
            }
        }
        
        if (Object.values(facets).some(facetItems => facetItems && facetItems.length)) {
            newSettings = {
                ...newSettings,
                query: {
                    ...newSettings.query,
                    facets: {
                        ...newSettings.query.facets,
                        ...Object.fromEntries(
                            Object.entries(facets).filter(([, items]) => items && items.length).map(([key, items]) => [key, { items, bucket: 'should' }])
                        )
                    }
                }
            }
        }

        store.dispatch(SearchActions.settingsChange(newSettings));
        ReactDOM.render(<Provider store={store}><App st={store} contentTypes={contentTypes} telemetry={telemetry} showFilters={JSON.parse(keys['showFilters'])} showLoadMore={JSON.parse(keys['showLoadMore'])} /></Provider>, element)
    })
}