import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    Search,
    Metrics,
    SearchActions,
    MetricsActions,
} from "@bravo/react-search";
import * as SearchActionTypes from "@bravo/react-search/src/Search/actions/actionTypes";
import keycode from "keycode";

import Documents from "../../components/Documents";
import Facets from "../../components/Facets";
import PagingWithNumbers from "../../components/PagingWithNumbers";
import Ranges from "../../components/Ranges";
import SearchInput from "../../components/SearchInput";
import Sort from "../../components/Sort";
import FacetTabs from "../../components/Facets/tabs";
import ResultsMessage from "../../components/ResultsMessage";
import ViewAllButton from "../../components/ViewAllButton";
import NoResults from "../../components/NoResults";
import Modal from "../../components/Facets/modal";

import "../../styles/app.scss";

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        searchActions: bindActionCreators(SearchActions, dispatch),
        metricsActions: bindActionCreators(MetricsActions, dispatch),
    };
};

const App = (props) => {
    //console.log("Redux Store/Actions:", props);

    const {
        search,
        searchActions,
        idsaSuppAggData,
        isMember,
        oupSsoPrefix,
        st,
        metrics,
        metricsActions,
        auxBody,
        auxGamHtml,
        auxGamId,
    } = props;
    const { trackQueryEvent } = metricsActions;

    const {
        ui,
        documents,
        facets,
        keyword,
        paging,
        ranges,
        settings,
        sort,
        tab,
    } = search;
    const { pageSize } = settings.query;
    const { isFetching } = ui;
    const hasDocuments = documents.list.length > 0;
    const viewAll = documents.total > pageSize;
    const hasFacetSelections = Object.keys(facets.selected).length > 0;

    const { onHashChange, refreshCancel, searchInitialize, searchSubmit } =
        searchActions;

    const {
        onKeywordChange,
        onKeywordSubmit,
        onPageNext,
        onPagePrev,
        onPageChange,
    } = searchActions;
    const {
        onSortChange,
        onTabSelect,
        onFacetClear,
        onFacetTermToggle,
        onRangeChange,
        settingsChange,
    } = searchActions;

    const [usePagination, setUsePagination] = React.useState(true);
    const [offset, setOffset] = React.useState(0);

    const [facetsSlot, setFacetsSlot] = React.useState();

    React.useEffect(() => {
        window.onscroll = () => {
            const pageYOffset = window.pageYOffset;
            if (pageYOffset + 200 <= offset) {
                return;
            }
            setOffset(pageYOffset);
            if (usePagination) {
                return;
            }
            if (documents.list.length >= documents.total) {
                return;
            }
            onPageNext();
        };
        return () => (window.onscroll = undefined);
    }, [documents, usePagination]);

    const onFacetClearCustom = () => {
        onFacetClear();

        setUsePagination(true);

        const newSettings = {
            ...settings,
            query: {
                ...settings.query,
                pageSize: 25,
            },
        };

        //console.log("New Settings: ", newSettings);

        st.dispatch(settingsChange(newSettings));
        onPageChange(0);
    };

    //update sort to score if there is a search term
    const onKeywordSubmitCustom = (evt) => {
        let sortId = "date_na_dt";

        if (evt.target.value) sortId = "score";

        onSortChange({ id: sortId, dir: "desc" });
        onKeywordSubmit(evt);
    };

    //custom key down fn that calls our cutom submit
    const onKeywordKeyDownCustom = (evt) => {
        if (keycode.isEventKey(evt, "enter")) {
            return onKeywordSubmitCustom(evt);
        }
        return {
            type: SearchActionTypes.KEYWORD_KEYDOWN,
            error: false,
            payload: {
                evt,
            },
        };
    };

    const bodyEl = React.useRef(null);
    React.useEffect(() => {
        //no auto-scroll on initial page load
        if (bodyEl.current.getBoundingClientRect().top < 0) {
            bodyEl.current.scrollIntoView({
                block: "start",
                inline: "nearest",
                behavior: "smooth",
            });
        }
    }, [documents]);

    React.useEffect(() => {
        if (facetsSlot && auxGamHtml && auxGamId) {
            googletag.cmd.push(function () { googletag.display(auxGamId); });
        }
    }, [facetsSlot]);

    return (
        <div ref={bodyEl} className={isFetching ? "bs-app loading" : "bs-app"}>
            <Metrics {...metrics} trackQueryEvent={trackQueryEvent} />
            <Search
                onHashChange={onHashChange}
                refreshCancel={refreshCancel}
                search={search}
                searchInitialize={searchInitialize}
                searchSubmit={searchSubmit}
                deepLinking={false}
            />

            {hasDocuments ? (
                <div className="bravoSearchResults">
                    <div className="bravo--header mb-5"></div>

                    {auxBody && (
                        <div className="row">
                            <div className="col-lg-8 col-xl-9 offset-lg-4 offset-xl-3">
                                <div
                                    className="copy-header"
                                    // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                                    dangerouslySetInnerHTML={{
                                        __html: auxBody,
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    <div className="row">
                        {facets.groups.length > 1 && (
                            <div className="col-lg-4 col-xl-3 d-none d-lg-block">
                                <div
                                    id="bravoSearchFacetPanelLg"
                                    className="facet-panel-wrap"
                                >
                                    <h4 className="mb-4">Filters</h4>
                                    <div className="facets" ref={setFacetsSlot}>
                                        <Ranges
                                            onRangeChange={onRangeChange}
                                            ranges={ranges}
                                            customHeading="Published Date"
                                        />
                                        <Facets
                                            facets={facets}
                                            onFacetTermToggle={onFacetTermToggle}
                                            suppAggData={idsaSuppAggData}
                                            docCount={documents.total}
                                        />
                                        {hasFacetSelections && (
                                            <div className="clear-filters text-center">
                                                <a
                                                    onClick={onFacetClearCustom}
                                                    className="btn btn-primary"
                                                    data-feathr-click-track="true"
                                                >
                                                    Clear Filters
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {(auxGamHtml && auxGamId) && (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: auxGamHtml,
                                        }}
                                    />
                                )}

                            </div>
                        )}
                        <div className="col-lg-8 col-xl-9 search-columns">
                            <div className="d-lg-none">
                                <div className="col-12 results-summary bravo--header pb-3 mb-4">
                                    <ResultsMessage
                                        total={documents.total}
                                        searchTerm={keyword.searchTerm}
                                    />
                                </div>
                                <SearchInput
                                    keyword={keyword}
                                    onKeywordChange={onKeywordChange}
                                    onKeywordKeyDown={onKeywordKeyDownCustom}
                                    onKeywordSubmit={onKeywordSubmitCustom}
                                    placeholder="Search"
                                />
                            </div>
                            <div className="results-header">
                                <div className="results-summary d-none d-lg-block w-100">
                                    <div className="row results-summary-row">
                                        <div className="col-auto flex-shrink-1 flex-grow-1 results-summary-input">
                                            <SearchInput
                                                keyword={keyword}
                                                onKeywordChange={onKeywordChange}
                                                onKeywordKeyDown={onKeywordKeyDownCustom}
                                                onKeywordSubmit={onKeywordSubmitCustom}
                                                placeholder="Search"
                                            />
                                        </div>
                                        <div className="col-auto flex-shrink-1 flex-grow-1 d-flex justify-content-end results-summary-sort">
                                            <Sort
                                                onSortChange={onSortChange}
                                                sort={sort}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {facets.groups.length > 1 && (
                                    <Modal
                                        facets={facets}
                                        onFacetTermToggle={onFacetTermToggle}
                                        onRangeChange={onRangeChange}
                                        ranges={ranges}
                                        onFacetClear={onFacetClear}
                                        suppAggData={idsaSuppAggData}
                                        docCount={documents.total}
                                        customHeading="Published Date"
                                    />
                                )}
                            </div>
                            {Object.keys(tab).length !== 0 && (
                                <FacetTabs
                                    onTabSelect={onTabSelect}
                                    tab={tab}
                                />
                            )}
                            <Documents
                                documents={documents}
                                onDocumentClick={searchActions.onDocumentClick}
                                isMember={isMember}
                                oupSsoPrefix={oupSsoPrefix}
                                pgTemplate={true}
                                searchTerm={keyword.searchTerm}
                            />
                            {hasDocuments && viewAll && (
                                <div className="row">
                                    <div className="col-12 paging-alt">
                                        <PagingWithNumbers
                                            paging={paging}
                                            onPageNext={onPageNext}
                                            onPagePrev={onPagePrev}
                                            onPageChange={onPageChange}
                                            maxDisplayCount={pageSize}
                                            totalDocumentCount={documents.total}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row justify-content-center">
                    <div className="col-12">
                        <NoResults
                            st={st}
                            searchSubmit={searchSubmit}
                            isFetching={isFetching}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
