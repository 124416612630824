const css = `.bs-suggest-group {
  cursor: pointer;
}
.bs-suggest-group i {
  text-decoration: underline;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvdnN0cy93b3JrLzEvcy9zcmMvSURTQS5XZWIvU3RhdGljL2JyYXZvLXJlYWN0LXNlYXJjaC9zcmMvc3R5bGVzIiwic291cmNlcyI6WyJzcGVsbGNoZWNrLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDSTs7QUFDQTtFQUNJIiwic291cmNlc0NvbnRlbnQiOlsiLmJzLXN1Z2dlc3QtZ3JvdXAge1xuICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgICBpIHtcbiAgICAgICAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmU7XG4gICAgfVxufSJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
