import React from 'react'
import clsx from 'clsx'

function MediaDocument(props){
    //rs: added extra prop for date_na_dt
    const {title, url, type, date_na_dt, oupSsoPrefix, image, featured, isMember, membersOnly_na_bool} = props

    const showLock = isMember === 'false' && membersOnly_na_bool === 'true'

    return (
            // {featured ? "media-document media-document-featured col-12":"media-document col-12 col-lg-4"}
            <a className="media-document col-12 col-lg-4" href={url}>
                <figure className={clsx("figure position-relative mb-3", showLock && "lock-icon")}>
                    {/* <span className="fas fa-play-circle"></span> */}
                    <img className="img-fluid" src={image}></img>
                </figure>
                <h3 className="title">{title}</h3>
                <div className="sub-header">
                    {/* rs: format the date to make it pretty for humans */}
                    <span>Published: { (new Date(date_na_dt)).toLocaleDateString() }</span>
                </div>
            </a>
    )
}

export default MediaDocument