
import React from 'react'
import { termHighlighter, snippet, dateGetPartial } from './utils'

const Blog = ({url, title, subtitle, listingImage_na_str, authorNames_na_str, publishedDate_na_dt, summary, description, searchTerm, showImages, body}) => {
    const formattedSummary = summary ? termHighlighter(snippet(summary), searchTerm) : termHighlighter(snippet(body), searchTerm );
    const authorNamesString = (authorNames_na_str && Array.isArray(authorNames_na_str)) ? authorNames_na_str.join(', ') : authorNames_na_str;

    return(
        <a className="gridlisting-card blog-document" href={url}>
            <div className="gridlisting-card-topwrap">
                {/*
                    <div className="gridlisting-card-image" role="presentation">
                        <div className="gridlisting-card-shape">
                            <img src="/globalassets/global-blocks/hero-blocks/hero-12.jpg?width=330&amp;height=148&amp;format=webp&amp;quality=100&amp;rxy=0.5%2c0.5&amp;rmode=crop" />
                        </div>
                    </div>
                    */}
                <div className="gridlisting-card-body">
                    <div className="row gridlisting-card-pills">
                        {document.category_na_str && document.category_na_str.length ? <div className="col-auto"><span className="pill pill-green text-uppercase">{document.category_na_str[0]}</span></div> : null}
                        {document.membersOnly ? <div className="col-auto"><span className="pill pill-purple text-uppercase"><span className="icon icon-account"></span>Member Only</span></div> : null}
                    </div>
                    <h4 className="gridlisting-card-title-link text-green line-clamp-2">{title}</h4>
                </div>
            </div>
            <div className="gridlisting-card-info">
                {/* date and read time */}
                {publishedDate_na_dt ? (
                    <div className="gridlisting-card-info-row">
                        <div className="gridlisting-card-info-icon"><span className="fa-regular fa-calendar"></span></div>
                        <div className="gridlisting-card-info-text"><strong>Date&nbsp;</strong>
                            {(new Date(publishedDate_na_dt)).toLocaleDateString()}
                        </div>
                    </div>
                ) : null}
                {document.contentLength ? (
                    <div className="gridlisting-card-info-row">
                        <div className="gridlisting-card-info-icon"><span className="fa-solid fa-stopwatch"></span></div>
                        <div className="gridlisting-card-info-text">{document.contentLength}</div>
                    </div>
                ) : null}
                <div className="gridlisting-card-clip">
                    <div className="card-arrow-animated">
                        <div className="card-arrow-animated-bl"></div>
                        <div className="card-arrow-animated-tr"></div>
                        <div className="card-arrow-animated-in">
                            <div className="card-arrow-animated-icon">
                                <span className="fas fa-arrow-right"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default Blog
