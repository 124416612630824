import * as React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {Search, Metrics, SearchActions, MetricsActions} from '@bravo/react-search'

import Documents from '../../components/Documents'
import Facets from '../../components/Facets'
import FacetCloud from '../../components/FacetCloud'
import PagingWithNumbers from '../../components/PagingWithNumbers'
import Ranges from '../../components/Ranges'
import SearchInput from '../../components/SearchInput'
import Sort from '../../components/Sort'
import FacetTabs from '../../components/Facets/tabs'
import Modal from '../../components/Facets/modal'
import ResultsMessage from '../../components/ResultsMessage'
import NoResults from '../../components/NoResults'
import DocumentTile from '../../components/Documents/tile'

import "../../styles/app.scss"

const mapStateToProps = (state, ownProps) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        searchActions: bindActionCreators(SearchActions, dispatch),
        metricsActions: bindActionCreators(MetricsActions, dispatch),
    }
}

const App = (props) => {
    const {search, searchActions, idsaSuppAggData, isMember, oupSsoPrefix, st, metrics, metricsActions} = props
    const {trackQueryEvent} = metricsActions

    const {ui, documents, facets, keyword, paging, ranges, settings, sort, tab} = search
    const {pageSize} = settings.query;
    const {isFetching} = ui
    const hasDocuments = documents.list.length > 0
    const hasFacetSelections = Object.keys(facets.selected).length > 0

    const {onHashChange, refreshCancel, searchInitialize, searchSubmit} = searchActions

    const {onKeywordChange, onKeywordKeyDown, onKeywordSubmit, onPageNext, onPagePrev, onPageChange} = searchActions
    const {onSortChange, onTabSelect, onFacetClear, onFacetTermToggle, onRangeChange} = searchActions

    const [facetsSlot, setFacetsSlot] = React.useState();

    const bodyEl = React.useRef(null)
    React.useEffect(() => {
        //no auto-scroll on initial page load
        if(bodyEl.current.getBoundingClientRect().top < 0) {
            bodyEl.current.scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"})
        }
    }, [documents]);

    React.useEffect(() => {
        if (facetsSlot) {
            facetsSlot.appendChild(document.getElementById('google-ads'));
            document.getElementById('google-ads').style.display = 'block';
        }
    }, [facetsSlot]);

    return (
        <div ref={bodyEl} className={(isFetching) ? "bs-app loading" : "bs-app"}>
            <Metrics 
                {...metrics}
                trackQueryEvent={trackQueryEvent}
            />
            <Search 
                onHashChange={onHashChange}
                refreshCancel={refreshCancel}
                search={search}
                searchInitialize={searchInitialize}
                searchSubmit={searchSubmit}
                deepLinking={false}
            />
            
            {(hasDocuments) ? (
                <div className="bravoSearchResults">
                    <div className="row">
                        <div className="bravo--header col-lg-12 d-none d-lg-block pb-3 mb-5">
                            <ResultsMessage
                                total={documents.total}
                                searchTerm={keyword.searchTerm}
                            />
                        </div>
                    </div>
                    <div className="row">
                        {(facets.groups.length > 1) && (
                            <div className="col-lg-4 col-xl-3 d-none d-lg-block">
                                <div id="bravoSearchFacetPanelLg" className="facet-panel-wrap">
                                    <h4 className="mb-4">Filters</h4>
                                    <div className="facets" ref={setFacetsSlot}>
                                        <FacetCloud
                                            facets={facets}
                                            onFacetTermToggle={onFacetTermToggle}
                                            onFacetClear={onFacetClear}
                                        />
                                        <Ranges 
                                            onRangeChange={onRangeChange}
                                            ranges={ranges}
                                        />
                                        <Facets
                                            facets={facets}
                                            onFacetTermToggle={onFacetTermToggle}
                                            suppAggData={idsaSuppAggData}
                                            docCount={documents.total}
                                        />
                                        {(hasFacetSelections) && (
                                            <div className="text-center">
                                                <a onClick={onFacetClear} className="btn btn-primary" data-feathr-click-track="true">Clear Filters</a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="col-lg-8 col-xl-9 search-columns">
                            <div>
                                <div className="d-lg-none">
                                    <div className="col-12 results-summary bravo--header pb-3 mb-4">
                                        <ResultsMessage 
                                            total={documents.total}
                                            searchTerm={keyword.searchTerm}
                                        />
                                    </div>
                                    <SearchInput
                                        keyword={keyword}
                                        onKeywordChange={onKeywordChange}
                                        onKeywordKeyDown={onKeywordKeyDown}
                                        onKeywordSubmit={onKeywordSubmit}
                                    />
                                </div>
                                <div className="results-header">
                                    <div className="results-summary d-none d-lg-block w-100 mb-4">
                                        <div className="row">
                                            <div className="col-8">
                                                <SearchInput
                                                    keyword={keyword}
                                                    onKeywordChange={onKeywordChange}
                                                    onKeywordKeyDown={onKeywordKeyDown}
                                                    onKeywordSubmit={onKeywordSubmit}
                                                />
                                            </div>
                                            <div className="col-4 col-4 d-flex justify-content-end">
                                                <Sort
                                                    onSortChange={onSortChange}
                                                    sort={sort}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {(facets.groups.length > 1) && (<Modal 
                                        facets={facets}
                                        onFacetTermToggle={onFacetTermToggle}
                                        onRangeChange={onRangeChange}
                                        ranges={ranges}
                                        onFacetClear={onFacetClear}
                                        suppAggData={idsaSuppAggData}
                                        docCount={documents.total}
                                    />)}
                                </div>
                                {(Object.keys(tab).length !== 0) && (
                                    <FacetTabs 
                                        onTabSelect={onTabSelect}
                                        tab={tab}
                                    />
                                )}
                                <div className="gridlisting-content">
                                    <div className="row gridlisting-row" role="list">
                                        {documents.list.map((doc) => (
                                            <DocumentTile key={doc.id} document={doc} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {(hasDocuments) && (
                                    <PagingWithNumbers
                                        paging={paging}
                                        onPageNext={onPageNext}
                                        onPagePrev={onPagePrev}
                                        onPageChange={onPageChange}
                                        maxDisplayCount={pageSize}
                                        totalDocumentCount={documents.total}
                                    />
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row justify-content-center">
                    <div className="col-12">
                        <NoResults 
                            st={st}
                            searchInitialize={searchInitialize}
                            searchSubmit={searchSubmit}
                            isFetching={isFetching}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
