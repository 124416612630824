import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import '../../styles/paging.scss'

const PagingWithNumbers = (props) => {
    const {paging, onPagePrev, onPageNext, onPageChange, maxDisplayCount, totalDocumentCount} = props
    const {pageIndex} = paging
    const pageNum = pageIndex + 1
    const lastPage = Math.ceil(totalDocumentCount / maxDisplayCount)
    let lastPageDisplay = pageNum

    //console.log("Paging w/ #'s info; totalDocumentCount: " + totalDocumentCount + ", maxDisplayCont: " + maxDisplayCount + ", last page: " + lastPage);

    const lastPaging = maxDisplayCount / 2
    if(totalDocumentCount < 2){
        return (
            <div className="d-none d-lg-block" >
                <div className="paging-standard"></div>
            </div>
        )
    }
    // get range of numbers to show, stopping at the next 5
    let pageNumbers = []
    let outputNumber = pageNum
    if(pageNum > 1){
        // include up to two previous numbers
        if(pageNum > 2){
            pageNumbers.push(pageNum - 2)
            pageNumbers.push(pageNum - 1)
        } else {
            pageNumbers.push(1)
        }
    }
    do{
        pageNumbers.push(outputNumber)
        outputNumber++
    } while (pageNumbers.length < lastPaging && outputNumber <= lastPage)

    return(
        <nav aria-label="BravoSearchPagination">
            <ul className="d-inline-flex pagination pagination-lg justify-content-start">
                <li 
                    key="chevron-left"
                    className={clsx("page-item", (pageNum === 1) && "d-none")}
                >
                    <a className="page-link"
                        onClick={() => { pageNum > 1 ? onPagePrev() : void(0) }} aria-label="Previous" data-feathr-click-track="true">
                        <span aria-hidden="true"><i className="fa fa-chevron-left"></i></span>
                        <span className="sr-only">Previous</span>
                    </a>
                </li>
                {pageNumbers.map(pn => {
                    lastPageDisplay = pn

                    return (
                        <li
                        key={pn}
                        className={clsx("page-item", (pn === pageNum) && "active")}
                    >
                        {pn === pageNum &&
                            <span className="page-link">{pn}<span className="sr-only">(current)</span></span>
                            || 
                            <a className="page-link" onClick={() => onPageChange(pn-1)} data-feathr-click-track="true">{pn}</a>
                        }
                    </li>
                    )
                })}
                <li
                    className={clsx("page-item", (lastPage === pageNumbers) && "d-none", )}
                >
                    {lastPage !== lastPageDisplay &&
                            <a className="page-link" onClick={() => onPageChange(lastPage-1)} data-feathr-click-track="true">{lastPage}</a>
                    }
                </li>
                <li 
                    key="chevron-right"
                    className={clsx("page-item", (pageNum === lastPage) && "d-none")}
                >
                    <a className="page-link"
                        onClick={() => { pageNum < lastPage ? onPageNext() : void(0) }} aria-label="Next" data-feathr-click-track="true">
                        <span aria-hidden="true"><i className="fa fa-chevron-right"></i></span>
                        <span className="sr-only">Next</span>
                    </a>
                </li>
            </ul>
        </nav>              

    )
}

PagingWithNumbers.propTypes = {
    paging: PropTypes.shape({
        pageIndex: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired
    }).isRequired,
    onPageNext: PropTypes.func.isRequired,
    onPagePrev: PropTypes.func.isRequired,
    maxDisplayCount: PropTypes.number.isRequired
}

export default PagingWithNumbers