
import React from 'react'
import { termHighlighter, snippet, dateGetPartial } from './utils'
import GridlistingCard from '../GridlistingCard/index.js';

const Blog = ({ url, title, subtitle, listingImage_na_str, authorNames_na_str, publishedDate_na_dt, summary, description, searchTerm, showImages, body, category_na_str, membersOnly, contentLength }) => {
    const formattedSummary = summary ? termHighlighter(snippet(summary), searchTerm) : termHighlighter(snippet(body), searchTerm);
    const authorNamesString = (authorNames_na_str && Array.isArray(authorNames_na_str)) ? authorNames_na_str.join(', ') : authorNames_na_str;
    console.log('authorNameString', authorNamesString)

    function renderPills() {

        if (!category_na_str || !category_na_str.length)
            return null;

        let pills = [];
        let maxCount = category_na_str.length > 3 ? 3 : category_na_str.length;
        for (let i = 0; i < maxCount; i++) {
            pills.push(
                <div key={i} className="col-auto" >
                    <span className="pill pill-green text-uppercase">{category_na_str[i]}</span>
                </div>);
        }
        return pills;
    }

    return (
        <GridlistingCard
            classes="search-document"
            url={url}
            pills={category_na_str}
            membersOnly={membersOnly}
            title={title}
            summary={formattedSummary}
            date={publishedDate_na_dt}
            dateName={"Published"}
            contentLength={contentLength}
            authors={authorNamesString}
        />
    )
}

export default Blog
