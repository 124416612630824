import React from 'react'
import PropTypes from 'prop-types'

function FacetCloud(props){
    const {facets, onFacetTermToggle, onFacetClear} = props
    const list = React.useMemo(() => {
        return facets.groups.reduce((acc, cv) => {
            let selected = []
            if(facets.selected[cv.id]){
               selected = facets.selected[cv.id].map(f => {
                    return {
                        facet: {
                            id: cv.id
                        },
                        item: {
                            docCount: 0,
                            key: f,
                            selected: true,
                            title: f
                        }
                    }
                })
            }
            return [...acc, ...selected]
        }, [])        
    }, [facets.groups])

    return (
        <React.Fragment>
        {list.length > 0 &&
            <div className="applied-filters">
                <span className="facet-title">Applied Filters</span>
                <ul className="">
                    {list.map(({item, facet}, idx) => (
                        <li key={idx}>
                            <span className="pill facet-pill text-uppercase" onClick={() => onFacetTermToggle(item, facet)}>
                                {item.title}
                                <i className="fa-regular fa-x"></i>
                            </span>
                        </li>
                    ))}
                    <li><span onClick={onFacetClear} className="clear-all">Clear All</span></li>
                </ul>
            </div>
        }
        </React.Fragment>
    )
}

FacetCloud.propTypes = {
    facets: PropTypes.object,
    onFacetTermToggle: PropTypes.func,
    onFacetClear: PropTypes.func
}

export default FacetCloud