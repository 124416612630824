'use strict';
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import {Utils, SearchActions} from '@bravo/react-search'

import App from './app'
import reducer from '../reducers/default'

export default function IDSAGridListingTemplate({element, keys, telemetry}){
    Utils.store.createSearchStore(reducer, keys, { telemetry, hashLinking: false }).then(store => {
        const settings = store.getState().search.settings;

        let newSettings = settings;

        if (keys.pageSize) {
            newSettings = {
                ...newSettings,
                query: {
                    ...newSettings.query,
                    pageSize: +keys.pageSize
                },
            }
        }

        const authors = keys.authors && JSON.parse(keys.authors);
        if (authors && authors.length) {
            newSettings = {
                ...newSettings,
                query: {
                    ...newSettings.query,
                    facets: {
                        ...newSettings.query.facets,
                        authorNames_na_str: {
                            items: authors
                        }
                    }
                },
            }
        }
        store.dispatch(SearchActions.settingsChange(newSettings));
        ReactDOM.render(<Provider store={store}><App st={store} contentTypes={JSON.parse(keys['contentTypes'] ?? '[]')} telemetry={telemetry} showFilters={JSON.parse(keys['showFilters'])} showLoadMore={JSON.parse(keys['showLoadMore'])} /></Provider>, element)
    })
}