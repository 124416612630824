const css = `.bs-date-range {
  min-width: 15vw;
  padding: 30px;
  border-radius: 8px;
  background: #F9F9F9;
}
.bs-date-range label {
  font-weight: bold;
}

.bs-date-range-body {
  display: flex;
  margin-bottom: 16px;
}
.bs-date-range-body input {
  padding: 15px;
  margin: 0 4px;
}

.bs-date-range-actions {
  display: flex;
  justify-content: space-around;
  margin: 8px;
}
.bs-date-range-actions .bs-button {
  flex-basis: 30px;
  width: 30px;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 10px;
  background: none;
  border: none;
  outline: none !important;
  cursor: pointer;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvdnN0cy93b3JrLzEvcy9zcmMvSURTQS5XZWIvU3RhdGljL2JyYXZvLXJlYWN0LXNlYXJjaC9zcmMvc3R5bGVzIiwic291cmNlcyI6WyJyYW5nZXMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDQTtFQUNJO0VBRUE7RUFDQTtFQUNBOztBQUVBO0VBQ0k7OztBQUlSO0VBQ0k7RUFDQTs7QUFFQTtFQUNJO0VBQ0E7OztBQUlSO0VBQ0k7RUFDQTtFQUNBOztBQUVBO0VBQ0k7RUFBaUI7RUFBWTtFQUFhO0VBQWU7RUFBbUI7RUFBaUI7RUFBYTtFQUF5QiIsInNvdXJjZXNDb250ZW50IjpbIlxuLmJzLWRhdGUtcmFuZ2Uge1xuICAgIG1pbi13aWR0aDogMTV2dztcblxuICAgIHBhZGRpbmc6IDMwcHg7XG4gICAgYm9yZGVyLXJhZGl1czogOHB4O1xuICAgIGJhY2tncm91bmQ6ICNGOUY5Rjk7XG5cbiAgICBsYWJlbHtcbiAgICAgICAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gICAgfVxufVxuXG4uYnMtZGF0ZS1yYW5nZS1ib2R5e1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgbWFyZ2luLWJvdHRvbTogMTZweDtcblxuICAgIGlucHV0IHtcbiAgICAgICAgcGFkZGluZzogMTVweDtcbiAgICAgICAgbWFyZ2luOiAwIDRweDtcbiAgICB9XG59XG5cbi5icy1kYXRlLXJhbmdlLWFjdGlvbnN7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWFyb3VuZDtcbiAgICBtYXJnaW46IDhweDtcblxuICAgIC5icy1idXR0b24ge1xuICAgICAgICBmbGV4LWJhc2lzOjMwcHg7IHdpZHRoOjMwcHg7IGZsZXgtZ3JvdzowOyBmbGV4LXNocmluazowOyBwYWRkaW5nLWxlZnQ6MTBweDsgYmFja2dyb3VuZDpub25lOyBib3JkZXI6bm9uZTsgb3V0bGluZTpub25lICFpbXBvcnRhbnQ7IGN1cnNvcjpwb2ludGVyO1xuICAgIH1cbn0iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
