﻿import React from 'react'

const GridlistingCard = ({ classes, url, imageUrl, pills, membersOnly, title, summary, date, dateName, contentLength, authors }) => {
    const renderImage = () => {
        return imageUrl ? (
            <div className="gridlisting-card-image" role="presentation">
                <div className="gridlisting-card-shape">
                    <img src={imageUrl}></img>
                </div>
            </div>
        ) : null;
    }

    const renderPills = () => {
        // Rule, only 3 normal pills allowed to appear at a time
        return pills.map((pill, index) => {
            return pill ? (
                <div className="col-auto" key={index}>
                    <span className="pill pill-green text-uppercase">
                        {pill}
                    </span>
                </div>
            ) : null
        }).slice(0, pills.length > 3 ? 3 : pills.length);
    }

    const renderMembershipPill = () => {
        return membersOnly ? (
            <div className="col-auto">
                <span className="pill pill-purple text-uppercase">
                    <span className="icon icon-account" />
                    Member Only
                </span>
            </div>
        ) : null;
    }

    const renderInfo = (info, icon, title = "") => {
        return info ? (
            <div className="gridlisting-card-info-row">
                <div className="gridlisting-card-info-icon"><span className={`fa-regular ${icon}`}></span></div>
                <div className="gridlisting-card-info-text">
                    {title ? (<strong>{title}&nbsp;</strong>) : null}
                    {info}
                </div>
            </div>
        ) : null;
    }

    const renderDate = () => {
        let formattedDate = new Date(date).toLocaleDateString(
            "en-US",
            {
                month: "long",
                day: "numeric",
                year: "numeric",
            }
        )
        return date ? renderInfo(formattedDate, 'fa-calendar', dateName) : null;
    }

    return (
        <a href={url} className={`${classes} gridlisting-card`} role="listitem">
            <div className="gridlisting-card-topwrap">
                {renderImage()}
                <div className="gridlisting-card-body">
                    {pills || membersOnly ? (
                        <div className="row gridlisting-card-pills">
                            {renderPills()}
                            {renderMembershipPill()}
                        </div>
                    ) : null}
                    <h4 className="gridlisting-card-title-link text-green line-clamp-2">{title}</h4>
                    {summary ? (
                        <div className="gridlisting-card-summary line-clamp-2">
                            {summary}
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="gridlisting-card-info">
                {renderDate()}
                {renderInfo(contentLength, 'fa-stopwatch')}
                {renderInfo(authors, 'fa-user', 'Authors')}

                {/* Hover Arrow */}
                <div className="gridlisting-card-clip">
                    <div className="card-arrow-animated">
                        <div className="card-arrow-animated-bl"></div>
                        <div className="card-arrow-animated-tr"></div>
                        <div className="card-arrow-animated-in">
                            <div className="card-arrow-animated-icon">
                                <span className="fas fa-arrow-right"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    )
}

export default GridlistingCard;