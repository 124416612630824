import { Metrics, MetricsActions, Search, SearchActions } from '@bravo/react-search'
import * as React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import clsx from 'clsx'
import Multimedia from '../../components/Documents/Multimedia'
import Facets from '../../components/Facets'
import Modal from '../../components/Facets/modal'
import FacetTabs from '../../components/Facets/tabs'
import NoResults from '../../components/NoResults'
import PagingWithNumbers from '../../components/PagingWithNumbers'
import Ranges from '../../components/Ranges'
import SearchInput from '../../components/SearchInput'
import Sort from '../../components/Sort'
import ViewAllButton from '../../components/ViewAllButton'
import ResultsMessage from '../../components/ResultsMessage'

import "../../styles/app.scss"

const mapStateToProps = (state, ownProps) => {
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        searchActions: bindActionCreators(SearchActions, dispatch),
        metricsActions: bindActionCreators(MetricsActions, dispatch),
    }
}

const App = (props) => {

    //console.log("Redux Store/Actions:", props);

    const {search, searchActions, idsaSuppAggData, isMember, oupSsoPrefix, st, metrics, metricsActions} = props
    const {trackQueryEvent} = metricsActions

    const {ui, documents, facets, keyword, paging, ranges, settings, tab, sort} = search
    const {pageSize} = settings.query;
    const {isFetching} = ui
    const hasDocuments = documents.list.length > 0
    const hasFacetSelections = Object.keys(facets.selected).length > 0

    const {onHashChange, refreshCancel, searchInitialize, searchSubmit, onSortChange} = searchActions

    const {onKeywordChange, onKeywordKeyDown, onKeywordSubmit, onPageNext, onPagePrev, onPageChange} = searchActions
    const {onTabSelect, onFacetClear, onFacetTermToggle, onRangeChange, settingsChange} = searchActions
    const showPaginationAndViewAll = pageSize <= documents.total

    const [usePagination, setUsePagination] = React.useState(true);
    const [offset, setOffset] = React.useState(0);

    const [facetsSlot, setFacetsSlot] = React.useState();
    
    React.useEffect(() => {
        window.onscroll = () => {
            const pageYOffset =  window.pageYOffset;
            if ((pageYOffset + 200) <= offset) { return; }
            setOffset(pageYOffset)
            if (usePagination) { return; }
            //console.log('document', documents);
            if (documents.list.length >= documents.total) { return; }
            onPageNext();
        }
        return () => window.onscroll = undefined
    }, [documents, usePagination]);

    React.useEffect(() => {
        if (facetsSlot) {
            facetsSlot.appendChild(document.getElementById('google-ads'));
            document.getElementById('google-ads').style.display = 'block';
        }
    }, [facetsSlot]);

    const onFacetClearCustom = () => {
        onFacetClear()

        setUsePagination(true)

        const newSettings = {
			...settings,
			query: {
				...settings.query,
                pageSize: 12
			}
		};

        //console.log("New Settings: ", newSettings);

		st.dispatch(SearchActions.settingsChange(newSettings));
        onPageChange(0);
    }

    const bodyEl = React.useRef(null)
    React.useEffect(() => {
        //only scroll if we are below the body el, this helps for initial page load
        //we do not want to scroll past ads on page load
        //if usePagination=false user is in view all mode so don't want to scroll to top on result loading
        if(bodyEl.current.getBoundingClientRect().top < 0 && usePagination) {
            bodyEl.current.scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"})
        }
    }, [documents, usePagination]);
    return (
        <div ref={bodyEl} className={clsx((isFetching) ? "bs-app loading" : "bs-app", "mb-5")}>
            <Metrics 
                {...metrics}
                trackQueryEvent={trackQueryEvent}
            />
            <Search
                onHashChange={onHashChange}
                refreshCancel={refreshCancel}
                search={search}
                searchInitialize={searchInitialize}
                searchSubmit={searchSubmit}
                deepLinking={false}
                //If using pagination need to go to next page instead of appending to current result set. 
                appendResults = {!usePagination}
            />
            
            {(hasDocuments) ? (
                <div className="bravoSearchResults multimedia">
                    <div className="row">
                        <div className="bravo--header col-lg-12 d-none d-lg-block pb-5 mb-5">
                            <h1 className="h2"><i>Multimedia</i> Library</h1>
                        </div>
                    </div>
                    <div className="row">
                        {(facets.groups.length >= 1) && (
                            <div className="col-lg-4 col-xl-3 d-none d-lg-block">
                                <div id="bravoSearchFacetPanelLg" className="facet-panel-wrap">
                                    <h4 className="mb-4">Filters</h4>
                                    <div className="facets" ref={setFacetsSlot}>
                                        <Facets
                                            facets={facets}
                                            onFacetTermToggle={onFacetTermToggle}
                                            suppAggData={idsaSuppAggData}
                                            docCount={documents.total}
                                        />
                                        <Ranges 
                                            onRangeChange={onRangeChange}
                                            ranges={ranges}
                                        />
                                        {(hasFacetSelections) && (
                                            <div className="text-center">
                                                <a onClick={onFacetClearCustom} className="btn btn-primary" data-feathr-click-track="true">Clear Filters</a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="col-lg-8 col-xl-9 search-columns">
                            <div>
                                <div className="d-lg-none">
                                    <div className="col-12 results-summary bravo--header pb-3 mb-4">
                                        <ResultsMessage
                                            total={documents.total}
                                            searchTerm={keyword.searchTerm}
                                        />
                                    </div>
                                    <SearchInput
                                        keyword={keyword}
                                        onKeywordChange={onKeywordChange}
                                        onKeywordKeyDown={onKeywordKeyDown}
                                        onKeywordSubmit={onKeywordSubmit}
                                        placeholder="Search"
                                    />
                                </div>
                                <div className="results-header">
                                    <div className="results-summary d-none d-lg-block w-100 mb-4">
                                        <div className="row">
                                            <div className="col-8">
                                                <SearchInput
                                                    keyword={keyword}
                                                    onKeywordChange={onKeywordChange}
                                                    onKeywordKeyDown={onKeywordKeyDown}
                                                    onKeywordSubmit={onKeywordSubmit}
                                                    placeholder="Search"
                                                />
                                            </div>
                                            <div className="col-4 col-4 d-flex justify-content-end">
                                                <Sort
                                                    onSortChange={onSortChange}
                                                    sort={sort}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {(facets.groups.length > 1) && (<Modal
                                        facets={facets}
                                        onFacetTermToggle={onFacetTermToggle}
                                        onRangeChange={onRangeChange}
                                        ranges={ranges}
                                        onFacetClear={onFacetClear}
                                        suppAggData={idsaSuppAggData}
                                        docCount={documents.total}
                                    />)}
                                </div>
                                
                                {(Object.keys(tab).length !== 0) && (
                                    <FacetTabs 
                                        onTabSelect={onTabSelect}
                                        tab={tab}
                                    />
                                )}
                                <div className="gridlisting-content">
                                    <div className="row gridlisting-row" role="list">
                                    {documents.list.map((doc) => (
                                        <Multimedia key={doc.id} document={doc} width="full" />
                                    ))}
                                    </div>
                                </div>
                            </div>
                            {(hasDocuments && usePagination && showPaginationAndViewAll) && (
                                <div className="row">
                                <div className="col-12 paging-alt-2">
                                    <PagingWithNumbers
                                        paging={paging}
                                        onPageNext={onPageNext}
                                        onPagePrev={onPagePrev}
                                        onPageChange={onPageChange}
                                        maxDisplayCount={pageSize}
                                        totalDocumentCount={documents.total}
                                    />
                                </div>
                            </div>
                            )}
                            
                        </div>
                    </div>
                </div>
            ) : (
                <div className="row justify-content-center">
                    <div className="col-12">
                    <NoResults
                        st={st}
                        searchSubmit={searchSubmit}
                        isFetching={isFetching}
                    />
                    </div>
                </div>
            )}
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
