import React from 'react'
import PropTypes from 'prop-types'

import '../../styles/sort.scss'
import '../../styles/utils.scss'

const Sort = (props) => {
    const {sort, onSortChange} = props
    const {selected} = sort
    const onChange = (evt) => {
        const [id, dir] = evt.target.value.split('/')
        
        //console.log(`Sort onChange: id=${id} dir=${dir}`)
        
        onSortChange({id, dir})
    }
    if(sort.options.length <= 1){
        return null
    }
    return (
        <div className="sort">
            <span className="sort-text">Sort By</span>
            <div className="btn-group">
                <form role="form">
                    <div className="bbSearchSortBySelect">
                        <select id="sortSelect" onChange={onChange} value={`${selected.id}/${selected.dir}`}>
                            {sort.options.map((opt, i) => {
                                return (
                                    <option key={i} value={`${opt.id}/${opt.dir}`} >
                                        {opt.title}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </form>
            </div>
        </div>
    )
}
Sort.propTypes = {
    sort: PropTypes.shape({
        selected: PropTypes.shape({
            id: PropTypes.string,
            dir: PropTypes.string,
            title: PropTypes.string
        }),
        options: PropTypes.array
    }).isRequired,
    onSortChange: PropTypes.func.isRequired
}
export default Sort