import React, { useEffect, useState, useMemo} from 'react'
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

const yearFrom = (dateStr) => (dateStr ? new Date(dateStr).getFullYear() : undefined);

const DateRangeGroup = ({ range, onRangeChange }) => {
    const [sliderRef, setSliderRef] = useState();
    const [groupExpanded, setGroupExpanded] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 991);

    useEffect(() => {
        const handleResize = () => {
            const isSmall = window.innerWidth <= 991;
            setIsSmallScreen(isSmall);

            if (isSmall) {
                setGroupExpanded(false);
            } else {
                setGroupExpanded(true);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (sliderRef) {
            const slider = noUiSlider.create(sliderRef, {
                start: [yearFrom(range.min || range.limitMin), yearFrom(range.max || range.limitMax)],
                connect: true,
                range: {
                    min: 1900,
                    max: new Date().getFullYear(),
                },
                tooltips: true,
                step: 1,
                format: {
                    from: (num) => +num,
                    to: (num) => num.toFixed(0),
                },
                cssPrefix: 'idsa-slider-',
            });

            const updateHandlePositions = () => {
                const handles = sliderRef.querySelectorAll('.idsa-slider-origin');
                const values = slider.get().map((v) => parseFloat(v)); // Get current slider values as numbers
                const minYear = 1900; // Minimum year for the slider
                const maxYear = new Date().getFullYear(); // Maximum year for the slider

                handles.forEach((handle, index) => {
                    const value = values[index];
                    const percentage = ((value - minYear) / (maxYear - minYear)) * 100;
                    handle.style.transform = `translate(${percentage}%, 0)`;
                });
            };

            // Initial setup
            slider.on('update', updateHandlePositions);

            slider.on('change.one', ([minStr, maxStr]) => {
                onRangeChange(range.id, `${minStr}-01-01`, `${maxStr}-12-31`);
            });

            // Force an update of handle positions after setting slider values
            setTimeout(() => {
                updateHandlePositions();
            }, 0);

            return () => {
                slider.destroy();
            };
        }
    }, [sliderRef]);

    return (
        <div className="facet-wrapper">
            <div className="panel panel-default facets-group date-range" data-toggle="collapse" data-target="#collapseDate" id="accordion-date">
                <div className="panel-heading">
                    {isSmallScreen ? (
                        <a
                            className={`facet-title ${!groupExpanded ? 'collapsed' : ''}`}
                            aria-expanded={groupExpanded}
                            onClick={() => setGroupExpanded(!groupExpanded)}
                        >
                            {range.title}
                            <i class="fa-solid fa-chevron-up"></i>
                        </a>
                    ) : (
                        <span className="facet-title">{range.title}</span>
                    )}
                </div>
                <div className={`panel-collapse collapse ${groupExpanded ? 'show' : ''}`}>
                    <div className="panel-body date-range-wrapper">
                        <div ref={setSliderRef}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};


class xDateRangeGroup extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            min: props.range.min,
            max: props.range.max
        }
        this.handleClear = this.handleClear.bind(this)
        this.handleMaxChange = this.handleMaxChange.bind(this)
        this.handleMinChange = this.handleMinChange.bind(this)
    }
    handleMinChange(evt){
        this.setState({min: evt.target.value})
    }
    handleMaxChange(evt){
        this.setState({max: evt.target.value})
    }
    handleClear(field){
        this.setState({min: '', max:''})
        this.props.onRangeChange(field, '', '')
    }
    render(){
        const {range, onRangeChange} = this.props
        const {min, max} = this.state
        return (
            <div className="panel panel-default facets-group date-range" id="accordion-date">
                <a className="panel-heading">
                    <span>Date</span>
                </a>
                <div id="collapseDate" className="panel-collapse collapse show">
                    <div className="panel-body">
                        <input
                            id="date-range-slider"
                            type="range"
                            min={min}
                            max={max}
                            step="1"
                            value={[min, max]}
                            onChange={(e) => {
                                const [minValue, maxValue] = e.target.value.split(',');
                                this.setState({ min: minValue, max: maxValue });
                                onRangeChange(range.id, minValue, maxValue);
                            }}
                            style={{
                                width: '100%',
                                appearance: 'none',
                                padding: '10px 0',
                                background: 'transparent',
                            }}
                        />
                        <div className="date-range-markers">
                            <span>{min}</span>
                            <span>{max}</span>
                        </div>
                        {/* Old code - need to hook up the functionality to work with the range */}
                        {/*<div className="row">*/}
                        {/*    <div className="col-lg-6 date-range-sel">*/}
                        {/*        <label htmlFor="datepicker-min">From</label>*/}
                        {/*        <div className="date-range-sel-input">*/}
                        {/*        <input */}
                        {/*            id={range.title}*/}
                        {/*            type="date" */}
                        {/*            value={min} */}
                        {/*            onChange={this.handleMinChange}*/}
                        {/*            style={{padding:'8px 6px 8px 6px'}}*/}
                        {/*        />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-lg-6 date-range-sel">*/}
                        {/*        <label htmlFor="datepicker-max">To</label>*/}
                        {/*        <div className="date-range-sel-input">*/}
                        {/*            <input */}
                        {/*                type="date" */}
                        {/*                value={max} */}
                        {/*                onChange={this.handleMaxChange}*/}
                        {/*                style={{padding:'8px 6px 8px 6px'}}*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="row text-center justify-content-center">*/}
                        {/*    <div className="col-8"><button type="button" className="btn btn-primary margin orange" onClick={() => {onRangeChange(range.id, min, max); hideModals()}}>Apply</button></div>*/}
                        {/*</div>*/}
                        {/*<div className="row text-center justify-content-center">*/}
                        {/*    <div className="col-6"><button type="button" className="btn btn-link" onClick={() => {this.handleClear(range.id); hideModals()}}>Clear</button></div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
    }
}

export default DateRangeGroup