import React from "react";
import { termHighlighter, snippet, dateGetPartial } from "./utils";
import GridlistingCard from "../GridlistingCard/index.js";

function DocumentPG(props) {
    const { summary, name, url, date_na_dt, status, searchTerm, contentLength, journal, format_na_str, idTopic_na_str, topic_na_str, status_na_str } = props;
    const formattedSummary = termHighlighter(snippet(summary), searchTerm);
    const datePartial = dateGetPartial(date_na_dt);
    const canonStatusStr = Array.isArray(status) ? status.join(", ") : status;
    // TODO: Get this from the BE. Not working for normal bravo search either.
    const membersOnly = false; //TODO: Get this from the BE
    const renderPills = membersOnly || idTopic_na_str || topic_na_str || status_na_str;

    const renderPill = (string) => {
        string ? (
            <div className="col-auto">
                <span className="pill pill-green text-uppercase">
                    {string}
                </span>
            </div>
        ) : null;
    };

    return (
        <GridlistingCard
            classes="search-document"
            url={url}
            pills={[journal, format_na_str, idTopic_na_str, status_na_str]}
            membersOnly={membersOnly}
            title={name}
            summary={formattedSummary}
            date={date_na_dt}
            dateName={"Last Updated"}
            contentLength={contentLength}
        />
    );
}

export default DocumentPG;
