import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    Search,
    Metrics,
    SearchActions,
    MetricsActions,
} from "@bravo/react-search";
import DocumentTile from '../../components/Documents/tile'

const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        searchActions: bindActionCreators(SearchActions, dispatch),
        metricsActions: bindActionCreators(MetricsActions, dispatch),
    };
};

const CATEGORY_FACET_NAME = 'contentTypeFacet_na_str';

// a specific item in the content type selector
const ContentTypeSelectorItem = ({ title, checked, id, onChange }) => {
    return (
        <li className="idsa-tab-pill" role="tab">
            <input
                type="checkbox"
                checked={checked}
                id={id}
                onChange={onChange}
            />
            <label htmlFor={id}>{title}</label>
        </li>
    );
};

// the selector for all the content types
const ContentTypeSelector = ({ facets, onFacetTermToggle, onFacetClear, contentTypes }) => {
    const contentTypeFacet = facets.groups.find(
        (facet) => facet.id === CATEGORY_FACET_NAME
    );

    if (!contentTypeFacet || contentTypes.length === 1) {
        return null;
    }

    return (
        <div role="tablist">
            <ul className="idsa-tab-nav">
                <ContentTypeSelectorItem
                    id="content-type-all"
                    title="All"
                    checked={!contentTypeFacet.items.some((item) => item.selected)}
                    onChange={() => onFacetClear(contentTypeFacet)}
                />
                {contentTypeFacet.items.map((item, idx) => (
                    <ContentTypeSelectorItem
                        key={item.key}
                        id={`content-type-${idx}`}
                        title={item.title}
                        checked={item.selected}
                        onChange={() => onFacetTermToggle(item, contentTypeFacet)}
                    />
                ))}
            </ul>
        </div>
    );
};

const App = (props) => {
    const [hasSetFacets, setHasSetFacets] = useState(false);
    const {
        contentTypes,
        showFilters,
        showLoadMore,
        search,
        searchActions,
        idsaSuppAggData,
        searchBody,
        isMember,
        oupSsoPrefix,
        st,
        isrtln,
        fullColumn
    } = props;
    const {
        onHashChange,
        refreshCancel,
        searchInitialize,
        searchSubmit,
        onPageNext,
        onFacetTermToggle,
        onFacetClear,
    } = searchActions;
    const {
        ui,
        documents,
        facets,
        paging
    } = search;
    const { isFetching } = ui;
    const isLoading = isFetching;
    const hasDocuments = documents.list.length > 0;

    return (
        <div>
            <Search
                onHashChange={onHashChange}
                refreshCancel={refreshCancel}
                search={search}
                searchInitialize={searchInitialize}
                searchSubmit={searchSubmit}
                deepLinking={false}
                appendResults={true}
            />
            <div> 
                {hasDocuments ? (
                    <div>
                        {showFilters ? (
                            <ContentTypeSelector
                                facets={facets}
                                onFacetTermToggle={onFacetTermToggle}
                                onFacetClear={onFacetClear}
                                contentTypes={contentTypes}
                            />
                        ) : null}

                        <div className="row" role="list">
                            {documents.list.map((doc) => (
                                <DocumentTile key={doc.id} document={doc} width="half"/>
                            ))}
                        </div>
                        <div className="text-center">
                            {isLoading ? (
                                <div>Loading...</div>
                            ) : ((showLoadMore && paging.total > paging.pageIndex + 1) ? (
                                <button className="btn btn-secondary gridlisting-morebtn" onClick={onPageNext}>
                                    <i
                                        className="fa fa-plus fa-xs me-1"
                                        aria-hidden="true"
                                    ></i>{" "}
                                    More
                                </button>) : <></>
                            )}
                        </div>
                    </div>
                ) : isLoading ? null : (
                    <div>No results were found</div>
                )}
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
