import React from 'react'
import PropTypes from 'prop-types'

import '../../styles/ranges.scss'

import DateRangeGroup from './dateRangeGroup'

const Ranges = (props) => {
    const {ranges, onRangeChange, customHeading} = props
    return (
        <div>
            {Object.keys(ranges).map(fieldName => {
                return (
                    <DateRangeGroup
                        key={fieldName}
                        range={ranges[fieldName]}
                        onRangeChange={onRangeChange}
                        customHeading={customHeading}
                    />
                )
            })}
        </div>
    )
}

Ranges.propTypes = {
    ranges: PropTypes.object.isRequired,
    onRangeChange: PropTypes.func.isRequired,
    customHeading: PropTypes.string
}

export default Ranges