import React, { useEffect } from 'react';
import PropTypes from 'prop-types'

import Facets from './index'
import Ranges from '../Ranges'
import Sort from '../../components/Sort'
import { hideModals } from './utils'

import '../../styles/facets.scss'

const Modal = (props) => {
    const { facets, onFacetTermToggle, onFacetClear, ranges, onRangeChange, suppAggData, docCount } = props
    const hasFacetSelections = Object.keys(facets.selected).length > 0

    useEffect(() => {
        const headerElement = document.querySelector('.idsa-header');
        const modalElement = document.getElementById('bravoSearchModal_Facets');
        const modalTrigger = document.getElementById('bravoModalTrigger');
        const modalClose = document.getElementById('bravoModalClose');
        const modalBackdrop = document.getElementById('modalBackdrop');

        const openModal = () => {
            headerElement.classList.add('zi-1');
            modalElement.classList.add('active');
        };

        const closeModal = () => {
            headerElement.classList.remove('zi-1');
            modalElement.classList.remove('active');
        };

        if (modalTrigger) modalTrigger.addEventListener('click', openModal);
        if (modalClose) modalClose.addEventListener('click', closeModal);
        if (modalBackdrop) modalBackdrop.addEventListener('click', closeModal);

        return () => {
            if (modalTrigger) modalTrigger.removeEventListener('click', openModal);
            if (modalClose) modalClose.removeEventListener('click', closeModal);
            if (modalBackdrop) modalBackdrop.removeEventListener('click', closeModal);
        };
    }, []);

    const clickFn = (evt) => {
        onFacetClear()
    }

    return (
        <div>
            <div className="d-lg-none bravoSearchModalbtn">
                <button type="button" id="bravoModalTrigger" className="btn btn-filters">
                    <svg width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23 1.23485C23 1.91683 22.4471 2.46969 21.7652 2.46969H1.23485C0.552861 2.46969 0 1.91683 0 1.23485C0 0.55286 0.55286 0 1.23485 0H21.7652C22.4471 0 23 0.55286 23 1.23485Z" fill="#33542b" />
                        <path d="M18.161 6.12425C18.161 6.80624 17.6081 7.3591 16.9261 7.3591H5.42584C4.74386 7.3591 4.19099 6.80624 4.19099 6.12425C4.19099 5.44226 4.74385 4.8894 5.42584 4.8894H16.9261C17.6081 4.8894 18.161 5.44226 18.161 6.12425Z" fill="#33542b" />
                        <path d="M14.6685 11.0139C14.6685 11.6959 14.1156 12.2487 13.4336 12.2487H8.91834C8.23635 12.2487 7.68349 11.6959 7.68349 11.0139C7.68349 10.3319 8.23635 9.77905 8.91833 9.77905H13.4336C14.1156 9.77905 14.6685 10.3319 14.6685 11.0139Z" fill="#33542b" />
                    </svg>
                    Filters
                </button>
            </div>
            <div id="bravoSearchModal_Facets" className="bravo-mobile-facets" tabIndex="-1" role="dialog" aria-hidden="true">
                <div id="modalBackdrop" className="bravo-modal-backdrop"></div>
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header justify-content-end">
                            <button id="bravoModalClose" className="close" role="button" aria-controls="idsa-header-main-nav" aria-label="Closes Facets" aria-expanded="true">
                                <span className="fas fa-close" role="presentation"></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h2 className="mb-4">Facets</h2>
                            <div id="bravoSearchFacetPanel" className="facet-panel-wrap">
                                <div className="facets menu">
                                {(facets.groups.length > 1) && (
                                    <div className="col-lg-4 col-xl-3">
                                        <div id="bravoSearchFacetPanelLg" className="facet-panel-wrap">
                                            <div className="facets">
                                                <Ranges
                                                    onRangeChange={onRangeChange}
                                                    ranges={ranges}
                                                />
                                                <Facets
                                                    facets={facets}
                                                    onFacetTermToggle={onFacetTermToggle}
                                                    suppAggData={suppAggData}
                                                    docCount={docCount}
                                                />

                                                {(hasFacetSelections) && (
                                                    <div className="text-center mt-3">
                                                        <a onClick={clickFn} className="btn btn-primary" data-feathr-click-track="true">Clear Filters</a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Modal.propTypes = {
    facets: PropTypes.shape({
        selected: PropTypes.object,
        groups: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            items: PropTypes.arrayOf(PropTypes.shape({
                key: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ]),
                docCount: PropTypes.number,
                selected: PropTypes.bool,
                title: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number
                ])
            })),
            title: PropTypes.string
        }))
    }).isRequired,
    onFacetTermToggle: PropTypes.func.isRequired,
    ranges: PropTypes.object.isRequired,
    onRangeChange: PropTypes.func.isRequired,
    onFacetClear: PropTypes.func.isRequired, 
    suppAggData: PropTypes.object.isRequired
}
export default Modal